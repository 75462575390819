.singleAsset-main-container {
  background-color: $white-2;
  .image {
    height: 324px;
    width: 100%;
    object-fit: cover;
    object-position: left;
  }

  .title {
    line-height: 36px;
    margin-bottom: 7px;
  }

  .description {
    width: 578px;
    font-size: 13px;
    line-height: 20px;
    margin-bottom: 27px;
  }

  .label {
    font-size: 13px;
    background-color: $grey;
    border: 1px solid $grey;
    height: 34px;
    width: 114px;
  }

  .back-button {
    margin-bottom: 40px;
  }

  .start-editing-button {
    margin-bottom: 170px;
    button {
      width: 234px;
      height: 44px;
    }
  }

  .font-size-medium {
    line-height: 28px;
  }
}
