.imprint-container {
  .imprint-banner {
    background-color: $yellow;
    margin-bottom: 30px;
    padding-bottom: 20px;
  }
  .imprint-content {
    color: $black;
    p {
      margin-bottom: 10px;
    }
  }
}