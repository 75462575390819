.micro-media-sub-page {
  background-color: $white-2;
  .media-sub-page {
    &_banner {
      background-color: $white;
      min-height: 159px;
    }
    &_text {
      color: $black;
      font-size: 13px;
      line-height: 22px;
      font-weight: 700;
    }
    &_tab {
      display: flex;
      align-items: center;
      justify-content: center;

      color: $yellow;
      background-color: $black;
      height: 55px;
      width: 128px;
      user-select: none;
    }
    &_form {
      margin-bottom: 50px;
      .title {
        position: absolute;
        top: -150px;
        &-input {
          font-size: 26px !important;
          font-weight: 700;
          line-height: 36px !important;
        }
      }
      .headline {
        &-title {
          font-weight: 700;
        }
        &-text {
          min-height: 132px;
        }
      }
      .ant-form-item-label label {
        color: $black;
        font-size: 11px;
        line-height: 28px;
        font-weight: 700;
      }
      .ant-input {
        color: $black;
        font-size: 13px;
        line-height: 22px;
        border: 2px solid $grey-6;
        &:hover, &:focus {
          border-right-width: 2px !important;
        }
        &:focus {
          box-shadow: none;
        }
      }
      .preview-component {
        cursor: pointer;
        margin-bottom: 20px;
      }
      .add-category-button {
        width: 44px;
        margin-left: 10px;
        margin-right: auto;
        span {
          font-size: 18px;
        }
      }
    }
    &_status {
      .group-status {
        &-item {
          font-size: 13px;
          font-weight: bold;
          border: 2px solid $black;
  
          &:hover {
            color: $yellow;
            background-color: $black;
          }
        }
        .ant-radio-button-wrapper:not(:first-child)::before {
          content: none;
        }
        .ant-radio-button-wrapper-checked {
          background-color: $yellow;
          border: 2px solid $yellow;
          box-shadow: none;
  
          &:hover {
            color: $black;
          }
        }
      }
    }
  }
  .form-title {
    color: $black;
    font-size: 14px;
    line-height: 28px;
    font-weight: 700;
    margin-bottom: 18px;
  }
  .sub-headline {
    padding: 24px 0;
    border-top: 1px solid $grey-8;
  }
  .preview-media-sub-page {
    .banner {
      background-color: $yellow;
      .preview-headline {
        font-size: 10px;
        line-height: 12px;
        padding-bottom: 4px;
      }
      .preview-headline-text {
        font-size: 3px;
        line-height: 6px;
        padding-bottom: 5px;
      }
    }
    .preview-image img {
      width: 100%;
      height: 185px;
      object-fit: cover;
    }
    .preview-category {
      margin-top: 16px;
      .preview-sub-headline {
        font-size: 10px;
        line-height: 12px;
        padding: 0;
      }
      .preview-sub-headline-text {
        font-size: 3px;
        line-height: 6px;
        padding-bottom: 5px;
      }
      &-items {
        margin-bottom: 20px;
        .preview-category-img_wraper {
          .preview-category-img {
            width: 100%;
            height: 102px;
            object-fit: cover;
          }
        }
        .preview-category-title {
          font-size: 7px;
          line-height: 12px;
          padding: 5px 0 3px;
        }
      }
    }
  }
}
.preview-modal-media-sub-page {
  .banner {
    background-color: $yellow;
    .preview-headline {
      padding-bottom: 7px;
    }
    .preview-headline-text {
      padding-bottom: 20px;
    }
  }
  .preview-image img {
    width: 100%;
    height: 500px;
    object-fit: cover;
  }
  .preview-category {
    margin-top: 30px;
    .preview-sub-headline-text {
      white-space: pre-wrap;
    }
    .preview-category-items {
      margin-top: 30px;
      .preview-category-img_wraper {
        margin-bottom: 10px;
        img {
          width: 100%;
          height: 320px;
          object-fit: cover;
        }
      }
      .preview-category-title {
        font-size: 16px;
        margin-bottom: 20px;
      }
    }
  }
}