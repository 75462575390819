.micro-site-media-sub-page {
  background-color: $white-2;
  .media-sub-page {
    &_banner {
      min-height: 116px;
      background-color: $yellow;
    }
    &-headline {
      color: $black;
      line-height: 28px;
      padding: 3px 0 14px;
      margin-bottom: 0;
    }
    &-headline-text {
      color: $black;
      font-size: 13px;
      line-height: 22px;
      max-width: 750px;
      padding-bottom: 26px;
      margin-bottom: 0;
    }
    &-image {
      margin-bottom: 100px;
      img {
        width: 100%;
        max-height: 816px;
        object-fit: cover;
        
        @media screen and (max-width: 1440px) {
          max-height: 600px;
        }

        @media screen and (max-width: 1024px) {
          max-height: 450px;
        }
      }
    }
    &_sub-headline-category {
      .media-sub-page_sub-headline {
        font-size: 20px;
        color: $black;
        font-weight: 700;
      }
      .media-sub-page_sub-headline-text {
        font-size: 13px;
        color: $black;
      }
    }
    &-sub-headline-category-items {
      .sub-headline-category-img_wraper {
        display: block;
        height: 450px;
        width: 100%;
        .sub-headline-category-img {
          width: inherit;
          height: inherit;
          object-fit: cover;
        }
      }
      .sub-headline-category-title {
        font-size: 20px;
        color: $black;
        font-weight: 700;
      }
    }
  }
}
