@font-face {
  font-family: 'ClanWebPro-News';
  src: url('../../../assets/fonts/web/ClanScWebPro-News.woff') format('woff');
}

.base-select {
  width: 234px;
  .ant-select-selector {
    font-family: 'ClanWebPro-News', sans-serif;
    height: 44px !important;
    border: 2px solid $black !important;
    border-radius: 0 !important;
    .ant-select-selection-item,
    .ant-select-selection-placeholder {
      line-height: 44px;
    }
  }
}
