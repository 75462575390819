.web2Publish-welcome-content {
  background-image: url('../../../../assets/images/web2publish-welcome.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding: 314px 0 120px;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  &_text {
    color: $white-2;
    font-size: 85px;
    font-family: 'ClanWebPro-Narrow-Black', sans-serif;
    line-height: 1;
    width: 100%;
    margin-bottom: 7px;

    span.description {
      font-size: 13px;
    }
  }

  &_description {
    color: $white-2;
    font-size: 14px;
    line-height: 18px;
    max-width: 578px;
    margin: 0;
  }

  &_button {
    width: 235px;
    height: 44px;
  }
}
