@font-face {
  font-family: 'ClanWebPro-News';
  src: url('../../../assets//fonts/web/ClanScWebPro-News.woff') format('woff');
}

.sidebar {
  position: absolute!important;
  background: $white;
  width: 175px !important;
  max-width: 175px !important;
  min-width: 175px !important;
  margin-top: -6px;
  .sidebar-menu {
    padding-top: 20px;
    padding-right: 15px;
    padding-bottom: 16px;
    background-color: $grey-2;
    .sidebar-menu_item {
      font-family: "ClanWebPro-News", sans-serif;
      background-color: $grey-2!important;

      &.ant-menu-item-selected {
        font-weight: bold;
        font-family: 'ClanWebPro-Bold', sans-serif !important;
      }
      .sidebar-menu_link {
        color: $white-2;
        .sidebar-menu_title {
          font-size: 11px;
          line-height: 15px;
          color: $white-2;
        }
      }
    }

    .sidebar-submenu_item {
      font-family: 'ClanWebPro-News', sans-serif;
      background-color: $grey-2;
      .ant-menu {
        background-color: $grey-2;
      }

      .ant-menu-submenu-title {
        padding-right: 0;

        .ant-menu-submenu-arrow {
          color: $white-2;
          left: 6px;
          transform: translateY(-1px);
          &::before {
            transform: rotate(45deg) translateY(-2.5px);
          }
          &::after {
            transform: rotate(-45deg) translateY(2.5px);
          }
        }
      }
      .ant-menu-submenu-selected
      .ant-menu-submenu-title
      .ant-menu-title-content
      .submenu_item-content_wrapper
      .ant-menu-title-content {
        .in-menu-link {
          font-weight: bold;
          font-family: 'ClanWebPro-Bold';
        }
      }
      .ant-menu-title-content {
        font-size: 11px;
        line-height: 15px;
        color: $white-2;
        margin-left: 8px;
      }

      .ant-menu-inline {
        .ant-menu-item {
          padding-left: 18px !important;
        }
      }
      .expand-button {
        position: relative;
        width: 15px;
        background-color: $grey-2;
        border: none;
        outline: none;
        padding: 0;
        margin-right: 7px;
        cursor: pointer;
        .arrow-icon, .arrow-icon-expand {
          position: absolute;
          top: 50%;
          border: solid $white-2;
          border-width: 0 2px 2px 0;
          display: inline-block;
          padding: 2px;
          transition: all 0.3s;
        }
        .arrow-icon {
          transform: translateY(-35%) rotate(-45deg);
        }
        .arrow-icon-expand {
          transform: translateY(-50%) rotate(45deg);
        }
      }
    }

    .icon-menu {
      margin-right: 8px;
      height: 14px;
      width: 14px;
      fill: $grey-2;
    }

    .icon-submenu {
      height: 14px;
      width: 14px;
      fill: $grey-2;
    }

    .ant-menu-item-selected a,
    .ant-menu-item-selected {
      background-color: $grey-2;
      .sidebar-menu_link {
        .sidebar-menu_title {
          color: $white-2;
          font-weight: bold;
        }
      }
    }

    .sidebar-submenu_item {
      .ant-menu {
        background-color: $grey-2;
      }

      .ant-menu-submenu-title {
        padding-right: 0;

        .ant-menu-submenu-arrow {
          color: $white-2;
          left: 6px;
          transform: translateY(-1px);
          &::before {
            transform: rotate(45deg) translateY(-2.5px);
          }
          &::after {
            transform: rotate(-45deg) translateY(2.5px);
          }
        }
      }
      .ant-menu-submenu-selected
      .ant-menu-submenu-title
      .ant-menu-title-content
      .submenu_item-content_wrapper
      .ant-menu-title-content {
        .in-menu-link {
          font-weight: bold;
          font-family: 'ClanWebPro-Bold', sans-serif;
        }
      }
    }
    .ant-menu-submenu-open {
      .ant-menu-submenu-title {
        .ant-menu-submenu-arrow {
          color: $white-2;
          left: 6px;
          transform: translateY(-1px) rotate(90deg);
        }
      }
    }
  }

  .add-menu {
    .ant-menu-submenu-title {
      padding-left: 0px !important;
      background-color: transparent;

      .ant-menu-submenu-arrow {
        display: none;
      }

      .submenu_item-content_wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex: 1;
      }

      .ant-menu-title-content {
        display: flex;
        margin-left: 0;
        .ant-menu-submenu-title {
          padding-left: 0 !important;
        }

        .in-menu-link {
          color: $white-2;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 105px;
          margin-top: 2px;

          &:hover {
            text-decoration: underline;
          }
        }

        .add-sub-page-btn {
          cursor: pointer;
          padding: 0;
          margin-left: 3px;
          color: $white-2;
          background: transparent;
          border: none;
        }
      }
    }
  }

  .ant-menu-inline {
    .ant-menu-item {
      width: calc(100% + 0px);
      margin: 0;

      .ant-menu-title-content {
        flex: none;
      }
    }

    .ant-menu-item-selected::after {
      transform: scaleY(0);
    }
  }
}
.sub-page-menu {
  padding: 0 !important;
  height: inherit !important;
  background-color: $grey-4;
  .ant-dropdown-menu-item {
    background-color: $grey-4 !important;
  }
}
