.privacy-policy {
  text-align: justify;

  &-banner {
    background-color: $yellow;
    padding-bottom: 15px;
  }

  &-content {
    padding-top: 40px;
    a {
      color: $black-2;
      text-decoration: underline;
    }
    &-w2p {
      background-color: $white-2;
    }
    .unordered-list {
      list-style-type: disc;
    }
  }

  &-w2p {
    background-color: $white-2;
  }
}