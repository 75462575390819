.mapo-administrator-content-management {
  background-color: $white-2;
  .mapo-content-management {
    &_banner {
      min-height: 147px;
      background-color: $white;

      .ant-select-open {
        .ant-select-arrow {
          transform: rotate(180deg);
        }
      }
    }
    &_title {
      color: $black;
      font-size: 26px;
      font-weight: bold;
      line-height: 36px;
    }
    &_select {
      transition: 0.5s;
      min-width: 234px;
      .ant-select-selector {
        background-color: $yellow !important;
        border: none;
        height: 44px;
        padding-left: 12px;
      }
      .ant-select-selection-placeholder,
      .ant-select-selection-item {
        color: $black;
        font-size: 13px;
        font-weight: bold;
        line-height: 22px;
        align-self: center;
        padding-top: 2px;
      }
      .ant-select-arrow {
        color: $black;
        transition: all 0.3s ease-in-out;
      }
    }

    &-table {
      .tile-desctiption {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-bottom: 0;
      }
      .tile-image,
      .tile-embedded-link {
        color: $blue-2;
      }
      .tile-preview {
        font-size: 16px;
      }
      .action {
        display: inline-flex;

        .edit-icon {
          cursor: pointer;
        }
        .delete-icon {
          cursor: pointer;
          margin: 0 10px;
        }
      }
      .status-active,
      .status-inactive {
        padding-top: 0;
        padding-bottom: 0;
        line-height: 20px;
      }
      .status-inactive {
        color: $white-2;
        background-color: $grey-2;
      }
      .drag-icon {
        cursor: grab;
        color: $black;
        font-size: 17px;
      }
    }
  }
}

.mapo-select-option {
  padding: 2px 0 4px;
  .ant-select-item {
    height: 44px;

    .ant-select-item-option-content {
      color: $black;
      font-size: 13px;
      font-weight: bold;
      line-height: 22px;
      align-self: center;
      padding-top: 2px;
    }
  }
  .ant-select-item-option-active {
    background-color: $grey;
  }
}

.mapo-content-management-row-dragging {
  display: table;
  overflow-wrap: break-word;
  td {
    p {
      margin: 0 !important;
    }
    &:nth-child(1) {
      width: 12%;
    }
    &:nth-child(2), &:nth-child(4), &:nth-child(6) {
      width: 10%;
    }
    &:nth-child(3) {
      width: auto;
      max-width: 350px;
      p {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      @media screen and (max-width: 1440px) {
        max-width: 150px;
      }
    }
    &:nth-child(5) {
      width: 13%;
    }
    &:nth-child(7) {
      width: 12%;
      .action {
        .delete-icon {
          margin: 0 10px;
        }
      }
    }
  }
  .status-active, .status-inactive {
    line-height: 20px;
    padding-top: 0;
    padding-bottom: 0;
  }
  .status-inactive {
    color: $white-2;
    background-color: $grey-2;
  }
  .drag-icon {
    cursor: grab;
    color: $black;
    font-size: 17px;
  }
}
