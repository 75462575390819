.base-yellow-btn {
  background-color: $yellow;
  color: $black;
  border: unset !important;
  font-weight: bold;

  &:hover,
  &:focus,
  &:active {
    color: $yellow;
    background-color: $black;
    fill: $yellow;
  }
}

.vertical-divider {
  border-left: 2px solid $black-2;
  height: 33px;
}

.font-size-medium {
  font-size: 20px;
}
.font-size-big {
  font-size: 30px;
}

.ant-checkbox-checked .ant-checkbox-inner:after {
  border-color: $yellow !important;
}

.status-active,
.status-inactive {
  border-radius: 100px;
  font-size: 10px;
  line-height: 22px;
  text-align: center;
  padding: 5px 10px 4px;
  text-transform: capitalize;
  max-width: 100px;
}

.status-active {
  background-color: $green;
  color: $white;
}

.status-inactive {
  background-color: $grey;
  color: $grey-2;
}

.loading {
  padding: 100px;
  text-align: center;
}

.empty {
  padding: 100px;
}

.breadcrum {
  background-color: $yellow;
}

.group-options {
  .ant-radio-button-wrapper {
    border-radius: 20px;
    margin: 5px 10px;
    border: 2px solid $black;

    &:not(:first-child)::before {
      content: none;
    }
  }

  .ant-radio-button-wrapper-checked {
    background-color: $yellow !important;
    color: $black !important;
    border: 2px solid $yellow !important;

    &:not(:first-child)::before {
      content: none;
    }
  }
}

.group-status {
  .ant-radio-button-wrapper-checked {
    background: $yellow;
  }
  .ant-radio-button-wrapper-checked::selection {
    background: $yellow;
    color: $black-2;
  }

  .ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child {
    border-color: $yellow;
    border-right-color: $yellow;
  }
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    border-color: $yellow;
  }
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
    background: $yellow;
  }
}

.base-confirm-modal {
  .ant-modal-confirm-title {
    font-size: 18px;
    font-family: 'ClanWebPro-Bold', sans-serif;
  }
  .ant-modal-confirm-btns {
    .ant-btn {
      padding: 8px 18px;
      align-items: center;
      height: auto;
      color: $black-2;
      border-radius: 0px;
      font-family: 'ClanWebPro-Bold', sans-serif;
      text-transform: uppercase;
    }

    button:nth-child(1) {
      border: solid 1px $grey;
      background-color: $white-2;
      font-weight: bold;
    }

    button:nth-child(2) {
      background-color: $yellow;
      color: $black;
      border: unset !important;
      font-weight: bold;

      &:hover,
      &:active {
        color: $yellow;
        background-color: $black;
        fill: $yellow;
      }
    }
  }
}