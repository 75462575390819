.mapo-administrator-w2p-content-management {
  background-color: $white-2;
  .w2p-content-management_general {
    height: 147px;
    background: $grey-7;
  }

  .w2p-content-management-table {
    .w2p-content-list_editable {
      cursor: pointer;
    }
    .group-tags {
      font-size: 9px!important;
      .tag {
        text-align: center;
        font-size: 9px!important;
      }
    }
    @media screen and (max-width: 1600px) {
      .group-tags {
        display: flex;
        flex-direction: column;
      }
      .status-active,
      .status-inactive {
        font-size: 9px;
        padding: 5px 5px 4px;
      }
    }
  }

  .w2p-content-management_banner {
    background-color: $white;
    padding-top: 34px;
    padding-bottom: 77px;
    h1 {
      width: 70%;
      margin: 0 auto;

      font-size: 26px;
      line-height: 36px;
    }
  }

  .actions-group {
    display: inline-flex;

    .sync {
      margin-right: 3px;
    }
  }
}
