.mapo-administrator-w2p-content-single {
  background-color: $white-2;
  .w2p-content-single {
    .w2p-content-single-general {
      background: $white;
      h1 {
        font-size: 26px;
        text-transform: uppercase;
        font-weight: 700;
      }

      .editor-link {
        width: 300px;
        white-space: nowrap;
        overflow: hidden !important;
        text-overflow: ellipsis;
      }
    }

    .w2p-content-single-information {
      .w2p-content-single-form {
        .input-w2p-content-single,
        .textarea-w2p-content-single {
          width: 78.5%;
          border: 2px solid $grey-6;
          &:hover, &:active, &:focus {
            border-right-width: 2px !important;
          }
        }

        .textarea-w2p-content-single {
          height: 150px;
        }

        .image {
          width: 200px;
          margin-bottom: 20px;
        }
        .template-access {
          background-color: $grey-7;
        }

        .region-management-table,
        .company-management-table {
          .ant-table-thead {
            display: none;
            >span.ant-table-wrapper.ant-table-filter-trigger.active {
              color: black;
              font-size: 50px;
            }
          }
        }

        .region-management-table {
          .ant-table-selection-column {
            display: none;
          }

          .region-column {
            cursor: pointer;
          }

          .ant-table-tbody > tr.ant-table-row-selected > td {
            background-color: $yellow !important;
          }
        }

        .company-management-table {
          .ant-table-tbody {
            .ant-table-row {
              background-color: $grey-7;
            }
          }
        }

        .user-type {
          .ant-checkbox-group-item {
            margin-right: 24px;
          }
        }

        .btn-add-rule {
          margin-left: 55%;
        }

        .divider-role {
          width: 82%;
          border: 1px solid $black-2;
          background-color: $black-2;
        }
      }
    }
  }
}
