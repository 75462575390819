.user-management-list {
  .user-management-table {
    .user-management-list_editable {
      cursor: pointer;
    }
    .user-management-action {
      display: flex;
      align-items: center;
      .delete-icon {
        cursor: pointer;
        margin-left: 10px;
      }
    }
  }
}
