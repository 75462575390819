.terms-and-conditions {
  text-align: justify;
  .terms-banner {
    background-color: $yellow;
    padding-bottom: 20px;
  }

  .terms-content {
    background-color: $white-2;
    padding-top: 20px;
  }
  ol {
    counter-reset: item;
    li {
      display: block;
    }
    li:before {
      content: counters(item, ".") ". ";
      counter-increment: item;
    }
  }
  .nest-list {
    font-weight: normal;
    font-family: 'ClanWebPro-News', sans-serif;
  }
  .alpha-list {
    padding-left: 50px;
    li {
      display: list-item;
      &::before {
        content: none;
      }
    }
  }
}
