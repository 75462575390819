@font-face {
  font-family: 'ClanWebPro-News';
  src: url('../../../assets/fonts/web/ClanScWebPro-News.woff') format('woff');
}

.base-search-box {
  padding-bottom: 30px;
  font-family: "ClanWebPro-News", sans-serif;
  .filter-area {
    margin-top: 38px;
    .filter-alphabet {
      display: inline-flex;
      button {
        font-size: 11px;
        font-weight: 700;
        line-height: 28px;
        border: none;
        padding-right: 10px;
        background-color: transparent;
        color: $black;
        cursor: pointer;
  
        &.active {
          text-decoration: underline;
          font-weight: bold;
        }
      }
    }
  }

  .ant-input-group-wrapper {
    .ant-input-wrapper {
      .ant-input-affix-wrapper {
        border: 2px solid $grey-6;
        padding: 8px 15px 7px;
        &:hover {
          border-right-width: 2px !important;
        }
      }
      .ant-input-group-addon {
        display: none;
      }
    }
    .ant-input {
      color: $grey-6;
      font-size: 13px;
      line-height: 22px;
    }
    .ant-input-prefix {
      width: 25px;
      margin-right: 22px;
    }
  }
}
