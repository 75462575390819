@font-face {
  font-family: 'ClanWebPro-News';
  src: url('../../assets/fonts/web/ClanWebPro-News.woff') format('woff');
}

.header {
  &-wrapper {
    height: 88px;
    padding: 0;
    border-bottom: 6px solid $grey;
  }

  &-content {
    line-height: 52px;
    height: 100%;

    .ant-dropdown-open {
      background-color: $grey-4;
      .header_user-icon > g {
        fill: $black;
      }
    }
    .header_logo {
      padding-top: 5px;
      cursor: pointer;
  
      &--image {
        width: 125px;
      }
    }
    .header_title {
      font-family: 'ClanWebPro-News', sans-serif;
      text-transform: uppercase;
      padding-top: 5px;
      margin-left: 72px;
      user-select: none;
      &--white {
        color: $white-2;
      }
    }
    @media screen and (max-width: 1200px) {
      line-height: 36px;
      .header_logo--image {
        width: 105px;
      }
      .header_title {
        font-size: 26px;
        margin-left: 55px;
      }
    }

    @media screen and (max-width: 992px) {
      .header_logo--image {
        width: 90px;
      }
      .header_title {
        font-size: 20px;
        margin-left: 30px;
      }
      .header_buttons {
        .admin_button {
          font-size: 11px;
          padding: 0 20px;
        }
        .header_user {
          padding: 0 15px;
          &-icon, &-icon-black {
            width: 20px;
            height: 20px;
          }
        }
      }
    }

    @media screen and (max-width: 768px) {
      .header_logo--image {
        width: 75px;
      }
      .header_title {
        font-size: 20px;
        margin-left: 20px;
      }
      .header_buttons {
        .admin_button {
          font-size: 10px;
          padding: 0 15px;
        }
        .header_user {
          padding: 0 10px;
          &-icon, &-icon-black {
            width: 15px;
            height: 15px;
          }
        }
      }
    }
  }

  &_buttons {
    font-family: 'ClanWebPro-News', sans-serif !important;
    height: 100%;
    margin-left: auto;
    .admin_button {
      height: inherit;
      background-color: $white-2;
      padding: 0px 30px;
      font-weight: bold;
      font-size: 13px;
      cursor: pointer;
      border: none;
      text-transform: uppercase;
      &--active {
        background-color: $grey;
      }
    }
    .header_user {
      height: 100%;
      padding: 0 25px;
      cursor: pointer;

      display: flex;
      align-items: center;
      &-icon > g {
        fill: $white-2;
      }
      &-icon-black > g {
        fill: $black-2;
      }
    }
  }
}

.header-buttons-logout {
  top: 82px !important;
  min-width: 210px !important;
  .ant-dropdown-menu {
    height: 158px;
    background-color: $grey-4;
    border-radius: 0;
    box-shadow: none;
    padding: 20px 0;
    .ant-dropdown-menu-item {
      .logout-menu_button button {
        font-family: "ClanWebPro-News", sans-serif;
        width: 130px;
        height: 34px;
        span {
          font-size: 13px;
        }
      }

      .logout-menu_icon {
        margin: 0 20px;
        .icon-menu {
          height: 24px;
          width: 24px;
          fill: $grey-4;
          g {
            fill: $black;
          }
        }
        .title {
          font-family: "ClanWebPro-News", sans-serif;
          font-size: 11px;
          margin-top: 15px;
          font-weight: bold;
        }
      }
    }

    .ant-dropdown-menu-item:hover {
      background-color: $grey-4;
    }

    .logout-menu-item-icon {
      .ant-dropdown-menu-title-content {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
      }
    }
  }
}
