.mapo-administrator-user_content {
  background-color: $white-2;
  .single-user-management-content {
    h1 {
      font-size: 26px;
      text-transform: uppercase;
      font-weight: 700;
    }

    h3 {
      font-size: 14px;
      text-transform: uppercase;
    }

    .single-user-management-general,
    .single-user-management-information {
      .label {
        color: $grey-6;
        font-size: 11px;
      }
    }

    .single-user-management-general {
      background: $white;
    }

    .single-user-management-information {
      .divider-user-status {
        width: 300px;
      }
      .base-button {
        height: 34px;
        span {
          font-size: 13px;
          font-weight: bold;
        }
      }
    }

    .single-user-management-button {
      .btn-edit {
        min-width: 200px;
      }
      .btn-save-changes {
        width: 172px;
        margin-left: 200px;
      }
    }
  }
}
