.group-options {
  button {
    background-color: $white;
    margin: 4px 4px;
    text-transform: uppercase;
    padding: 5px 18px;
    border: 2px solid $black;
    cursor: pointer;

    &:first-child {
      margin-left: 0;
    }

    &.selected {
      background-color: $yellow;
      border-color: $yellow;
    }
  }
}
