@font-face {
  font-family: 'ClanWebPro-News';
  src: url('../../../../assets/fonts/web/ClanScWebPro-News.woff') format('woff');
}

.mapo-administrator-mapo-rule {
  background-color: $white-2;
}

.mapo-rule-content {
  padding-top: 34px;
  padding-bottom: 22px;
  h1 {
    font-family: 'ClanWebPro-News', sans-serif;
    font-size: 26px;
    text-transform: uppercase;
    font-weight: 700;
    line-height: 36px;
    margin-bottom: 0;
  }

  .paging {
    text-align: center;
    padding: 15px;
  }

  &_banner {
    background-color: $white;
  }

  &-tabs {
    .base-tabs {
      background-color: $white;
    }
    .ant-tabs-nav {
      width: 70%;
      margin: 0 auto;
    }
    .ant-tabs-content-holder {
      background-color: $white-2;
      padding-top: 32px;
    }
    .region-rule {
      h3 {
        font-size: 14px;
        padding-left: 12px;
        margin-bottom: 30px;
      }

      .rule {
        max-width: 23px;
        padding: 1px 3px 4px 3px;
        &.high-light {
          background-color: $yellow;
        }
      }

      .column-check {
        height: 25px;

        .ant-checkbox + span {
          font-family:"ClanWebPro-News", sans-serif;
          font-size: 12px;
          padding-left: 8px;
        }
      }
    }
  }
}
