.web2Publish-chili-editor-container {
  .group-header-button {
    padding: 10px 0;
    display: flex;

    .select-option-download {
      font-size: 13px;
      line-height: 22px;
      margin-left: auto;
      margin-right: 38px;
      .ant-select-selector {
        box-shadow: none !important;
        border-color: $grey !important;
        .ant-select-selection-placeholder {
          color: $black-2;
        }
      }
      &:hover, &:active, &:focus {
        .ant-select-selector {
          border-right-width: 2px !important;
        }
      }
    }
    .ant-select-open {
      .ant-select-selector {
        border-bottom: none !important;
      }
      .ant-select-arrow {
        transform: rotate(180deg);
      }
    }
    .ant-select-arrow {
      transition: 0.3s;
    }
  }

  .download {
    &:disabled {
      color: $black;
      background-color: $yellow;
    }
  }
}

.select-option-download-dropdown {
  font-size: 13px;
  top: 185px !important;
  padding: 0;
  border-left: 2px solid $grey;
  border-right: 2px solid $grey;
  border-bottom: 2px solid $grey;
  box-shadow: none;
  .ant-select-item {
    padding: 11px 12px;
  }
  .ant-select-item-option-active {
    background-color: $white-2;
  }
  .ant-select-item-option-selected {
    background-color: $grey;
  }
}