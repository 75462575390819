@font-face {
  font-family: 'ClanWebPro-Bold';
  src: url('../../../assets/fonts/web/ClanWebPro-Bold.woff') format('woff');
}

@font-face {
  font-family: 'ClanWebPro-Medium';
  src: url('../../../assets/fonts/web/ClanWebPro-Medium.woff') format('woff');
}

@font-face {
  font-family: 'ClanWebPro-Bold';
  src: url('../../../assets/fonts/web/ClanWebPro-Bold.woff') format('woff');
}

.breadcrumb-container {
  height: 44px;
  background-color: $yellow;

  .breadcrumb {
    width: 70%;
    margin: 0 auto;
    .breadcrumb-item {
      font-size: 11px;
      color: $grey-6;
      text-transform: capitalize;
      user-select: none;

      a,
      span.clickable {
        color: $grey-6;
        cursor: pointer;
        &:hover {
          color: $black;
        }
      }
    }
    .ant-breadcrumb-separator {
      font-size: 12px;
      font-weight: bold;
    }
  }
}

.ant-breadcrumb > ol > li:not(:last-child) {
  font-family: 'ClanWebPro-Medium', sans-serif;
}

.ant-breadcrumb > ol > li:last-child {
  font-family: 'ClanWebPro-Bold', sans-serif;
}
