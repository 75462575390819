.web2Publish-templates-by-cat-container {
  background-color: $white-2;
  .templates {
    margin-top: 40px;
    width: 100%;
    .template {
      padding: 0;
      &-image {
        width: inherit;
        height: inherit;
        object-fit: cover;
        object-position: left;

        &_wrapper {
          position: relative;
          display: block;
          height: 240px;
          width: 100%;
          margin-bottom: 12px;
          &:hover {
            .template-image_overlay {
              opacity: 0.98;
              &-content {
                margin-top: 110px;
              }
            }
          }
        }

        &_overlay {
          opacity: 0;
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          background-color: $yellow;
          transition: 0.5s ease-in-out;
          cursor: pointer;

          &-text {
            color: $black;
            font-size: 16px;
            line-height: 25px;
            padding-top: 1px;
            margin-bottom: 0;
          }

          &-content {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 60px;
            transition: 0.5s ease-in-out;
          }
        }
      }
      &-title {
        color: $black;
        font-size: 14px;
        font-weight: 700;
        line-height: 28px;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-bottom: 0;
      }
      &-size {
        color: $grey-6;
        font-size: 11px;
        font-weight: 500;
        line-height: 16px;
        margin-bottom: 11px;
      }
      &-description {
        color: $black;
        font-size: 13px;
        line-height: 20px;
      }
    }
  }

  .middle-content {
    h1 {
      text-transform: uppercase;
      margin-bottom: 11px;
    }
    h3 {
      font-size: 13px;
      line-height: 20px;
      margin-bottom: 20px;
    }
  }
}
