.mapo-administrator-w2p-content-single {
  .long-text {
    width: 100%;
  }

  .tile-type {
    width: 234px !important;
  }

  .type-box {
    padding: 11px;
    box-sizing: border-box;
    margin: 0;
    font-variant: tabular-nums;
    list-style: none;
    font-feature-settings: 'tnum', 'tnum';
    position: relative;
    display: inline-block;
    width: 234px;
    min-width: 0;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    line-height: 1.5715;
    background-color: #eee;
    background-image: none;
    cursor: not-allowed;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    transition: all 0.3s;
  }

  .preview-home {
    &-content-welcome {
      height: 32px;
      background-color: $yellow;
      p {
        font-size: 9px;
        line-height: 9px;
      }
    }
    &-breadcrumb {
      color: $grey-6;
      font-size: 5px !important;
      font-weight: bold;
      margin-bottom: 2px;
    }
    &-welcome-text {
      h2 {
        font-size: 6px;
        margin-bottom: 2px;
      }
      h3 {
        font-size: 3px;
      }
    }
    &-content-item {
      .item-image {
        width: 100%;
        height: 50px;
        object-fit: cover;
        margin-bottom: 5px;
      }
      .item-title {
        font-size: 6px;
        margin-bottom: 1px;
      }
      .item-description {
        font-size: 3px;
      }
    }
  }
}
