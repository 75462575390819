.base-button {
  height: 44px;
  &.ant-btn {
    border: 2px solid $black;
    &:hover,
    &:focus,
    &:active {
      color: $yellow;
      background-color: $black;
      fill: $yellow;
    }
  }
  span {
    font-size: 15px;
  }
}
