@font-face {
  font-family: 'ClanWebPro-News';
  src: url('../../assets/fonts/web/ClanWebPro-News.woff') format('woff');
}

.home-content {
  &_welcome {
    background-color: $yellow;
    padding-bottom: 15px;
    .welcome-user-name {
      font-size: 26px;
      line-height: 36px;
    }
  }

  &_pages {
    margin-bottom: 100px;

    .welcome-text {
      color: $black;
      &_title {
        font-size: 20px;
        line-height: 28px;
        margin-bottom: 7px;
      }
      &_description {
        font-family: 'ClanWebPro-News', sans-serif;
        font-size: 13px;
        line-height: 20px;
      }
    }

    .home-content_item {
      cursor: pointer;
      &-content {
        color: $black;
        min-height: 75px;
        .title h2 {
          color: $black;
          margin-bottom: 0;
          &:hover {
            color: $grey-6;
          }
        }
        .description {
          font-family: 'ClanWebPro-News', sans-serif;
          font-size: 13px;
          line-height: 20px;
          text-overflow: ellipsis;
          margin-top: 7px;
        }
      }
      .image {
        height: 195px;
        width: 100%;
        object-fit: cover;
        margin-bottom: 20px;
      }
    }
  }
}
