@font-face {
  font-family: 'ClanWebPro-News';
  src: url('../../../assets/fonts/web/ClanWebPro-News.woff') format('woff');
}
.base-table {
  .ant-table {
    font-family:"ClanWebPro-News", sans-serif
  }
  .ant-table-thead {
    tr > th > td {
      color: $black;
      background-color: $grey!important;
      font-weight: bold;
      font-size: 12px;
      line-height: 28px;
    }
    .ant-table-cell > div > .active{
      color: $black;
    }
    tr
      > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
      width: 0px;
    }

  }
  .ant-table-cell > td{
    background-color: $grey;
  }
  .ant-table-tbody {
    tr:nth-child(even) {
      background-color: $white;
    }
  .ant-table-cell > .rule{
    font-size: 9px !important;
  }
  .ant-table-cell > .actions-group{
    font-size: 20px !important;
  }
  .ant-table-cell>.status-active{
    font-size:9px !important;
  }
  .ant-table-cell>.status-inactive{
    font-size:9px !important;
  }

  .ant-table-column-title{
    font-size: 50px;
  }
    tr > td {
      font-size: 11px;
      line-height: 12px;
      border-bottom: 0px;
    }
  }

  .ant-pagination {
    justify-content: center !important;
    align-items: center;
    
    .ant-pagination-next:hover {
      .ant-pagination-item-link {
        background-color: $black;
        color: $yellow
      }
    }

    .ant-pagination-item {
      color: $black;
      font-size: 13px;
      line-height: 14px;
      min-width: unset;
      height: 14px;
      border: none;
      margin: 0 3px;

      a {
        padding: 0;
      }

    }
    .ant-pagination-item-active {
      font-weight: bold;
      a {
        color: black!important;
      }
    }
    .ant-pagination-prev {
      margin-right: 30px;
    }
    .ant-pagination-next {
      margin-left: 30px;
    }
    .ant-pagination-prev,
    .ant-pagination-next {
      display: flex;
      align-items: center;
      justify-content: center;
      button {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        width: 18px;
        height: 18px;
        color: $yellow;
        background-color: $black-2;
      }
      .ant-pagination-item-link {
        font-size: 11px;
      }
    }

    .ant-pagination-disabled {
      button {
        color: $black;
        background-color: #D8D8D8;
      }
    }

    .ant-pagination-options > .ant-select > .ant-select-selector{
      font-family: "ClanWebPro-News", sans-serif;
    }
  }
}
