.base-overlay-item {
  .item-image {
    width: inherit;
    height: inherit;
    object-fit: cover;
  
    &_wrapper {
      position: relative;
      display: block;
      height: 160px;
      width: 100%;
      margin-bottom: 20px;
      &:hover {
        .item-image_overlay {
          opacity: 0.98;
          &-content {
            height: 100%;
          }
        }
      }

      @media screen and (min-width: 2561px) {
        height: 200px;
      }
    }
  
    &_overlay {
      opacity: 0;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background-color: $yellow;
      transition: 0.5s ease-in-out;
  
      &-text {
        color: $black;
        font-size: 16px;
        line-height: 25px;
        padding-top: 1px;
        margin-bottom: 0;
        
      }
  
      &-content {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 70%;
        transition: 0.5s ease-in-out;
      }
    }

    &-small {
      position: relative;
      width: 100%;
      height: 100%;

      img {
        position: absolute;
        width: 100%;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%) scale(0.25);
      }
    }
  }
}
