.login {
  margin-top: 89px;
  background-color: $white !important;
  .login-content {
    width: 530px;
    margin: 0 auto;
    &-title {
      font-size: 36px;
      font-family: 'ClanWebPro-Narrow-Black', sans-serif;
      line-height: 46px;
    }
    &-sign-in {
      font-size: 24px;
      font-family: 'ClanWebPro-Medium', sans-serif;
      line-height: 46px;
    }
    .login-divider {
      width: 492px;
      margin: 0 auto;
      border: 1px solid $grey;
      background-color: $grey;
    }

    button {
      font-size: 15px;
      font-family: 'ClanWebPro-Bold', sans-serif;
      width: 320px;
      height: 44px;
    }

    .login-button {
      margin: 0 auto;
    }

    .text-container {
      text-transform: uppercase;
      padding-left: 105px;
    }

    .logo {
      padding-left: 105px;
    }

    .login-dealer-form {
      width: 320px;
      align-self: center;

      &-input {
        font-size: 13px;
        height: 44px;
        border: 2px solid $grey-6;

        &:hover, &:active, &:focus {
          box-shadow: none;
          border-right-width: 2px !important;
        }
      }
    }
  }
}
