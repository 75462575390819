.web2Publish-categories-content {
  .cat-text {
    font-size: 20px;
    line-height: 40px;
    margin-bottom: 0;
  }
  .category {
    .item-image-small {
      background-color: $black-2;
    }
  }
}
