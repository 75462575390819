.scroll-to-top_wrapper {
  width: 100%;
  text-align: right;
  background-color: $white;
  padding: 15px;

  &-white {
    background-color: $white-2;
  }

  .scroll-to-top_button {
    border: none;
    outline: none;
    background-color: inherit;
    .scroll-to-top_icon {
      border: solid $black;
      border-width: 0 4px 4px 0;
      display: inline-block;
      padding: 7px;
      transform: rotate(225deg);
      cursor: pointer;
    }
  }
}
