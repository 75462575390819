@font-face {
  font-family: 'ClanWebPro-News';
  src: url('../assets/fonts/web/ClanWebPro-News.woff') format('woff');
}

@font-face {
  font-family: 'ClanWebPro-Medium';
  src: url('../assets/fonts/web/ClanWebPro-Medium.woff') format('woff');
}

@font-face {
  font-family: 'ClanWebPro-Bold';
  src: url('../assets/fonts/web/ClanWebPro-Bold.woff') format('woff');
}

@font-face {
  font-family: 'ClanWebPro-Narrow-Black';
  src: url('../assets/fonts/web/ClanScWebPro-NarrBlack.woff') format('woff');
}

body {
  margin: 0;
  padding: 0;
  font-family: 'ClanWebPro-News', sans-serif;
  min-height: 100vh;
  font-size: 13px;
  background-color: $white !important;
}

#root {
  min-height: inherit;
  .App {
    min-height: inherit;
    .loading-bar {
      background-color: $black;
      height: 3px;
      position: absolute;
      z-index: 1;
    }

    .mapo-content {
      background-color: transparent;
      min-height: inherit;
    }

    .ant-spin-dot-item {
      background-color: $black;
    }
  }
}
