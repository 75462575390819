.micro-site-text-sub-page {
  background-color: $white-2;
  .text-sub-page {
    &_banner {
      min-height: 116px;
      background-color: $yellow;
      margin-bottom: 80px;
    }
    &-headline {
      color: $black;
      line-height: 28px;
      padding: 3px 0 14px;
      margin-bottom: 0;
    }
    &-headline-text {
      color: $black;
      font-size: 13px;
      line-height: 22px;
      max-width: 750px;
      padding-bottom: 26px;
      margin-bottom: 0;
    }
    &_image {
      img {
        width: 100%;
        max-height: 816px;
        object-fit: cover;
        
        @media screen and (max-width: 1440px) {
          max-height: 600px;
        }

        @media screen and (max-width: 1024px) {
          max-height: 450px;
        }
      }
    }
    &-categories {
      &:last-child {
        .text-sub-page-sub-headline-text {
          margin-bottom: 120px;
        }
      }
    }
    &-sub-headline {
      margin-bottom: 0;
    }
    &-sub-headline-text {
      padding-bottom: 80px;
      margin-bottom: 32px;
      border-bottom: 1px solid #ededed;
      white-space: pre-wrap;
    }
  }
}
