.micro-site-start-page {
  background-color: $white-2;
  .start-page {
    &_banner {
      min-height: 116px;
      background-color: $yellow;
    }
    &-headline {
      color: $black;
      line-height: 28px;
      padding: 3px 0 14px;
      margin-bottom: 0;
    }
    &-text-headline {
      color: $black;
      font-size: 13px;
      line-height: 22px;
      max-width: 750px;
      padding-bottom: 26px;
      margin-bottom: 0;
    }
    &-image {
      margin-bottom: 100px;
      img {
        width: 100%;
        max-height: 816px;
        object-fit: cover;

        @media screen and (max-width: 1440px) {
          max-height: 600px;
        }

        @media screen and (max-width: 1024px) {
          max-height: 450px;
        }
      }
      .place-holder-img {
        height: 560px;
      }
    }
    &-sub-headline {
      .sub-headline-image {
        img {
          width: 100%;
          height: 272px;
          object-fit: cover;
        }
      }
      .sub-headline-title {
        color: $black;
        font-size: 20px;
        font-weight: 700;
        line-height: 28px;
      }
      .sub-headline-description {
        color: $black;
        font-size: 13px;
        line-height: 20px;
        white-space: pre-wrap;
      }
      &:last-child {
        .sub-headline-divider {
          margin-bottom: 66px !important;
        }
      }
    }
  }
  .sub-headline-divider {
    hr {
      margin: 0;
      border-top: 1px solid $grey;
      border-bottom: 0;
    }
  }
}
