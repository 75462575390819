.web2Publish-my-templates-container {
  background-color: $white-2;
  .web2Publish-my-templates {
    &_banner {
      background-color: $yellow;
    }
    &--title {
      font-size: 30px;
      text-transform: uppercase;
      line-height: 28px;
      padding-top: 8px;
      margin-bottom: 15px;
    }
    &--text {
      font-size: 13px;
      font-weight: 500;
      line-height: 22px;

      max-width: 750px;
      margin-bottom: 0px;
    }
  }

  .template {
    padding: 0;

    .image-container {
      position: relative;
      p.date {
        position: absolute;
        background: $black;
        top: 20px;
        left: 20px;
        color: $white;
        padding: 5px;
      }

      .image {
        height: 186px;
        object-fit: cover;
        object-position: left;
      }
      .file-type {
        position: absolute;
        top: 20px;
        right: 20px;
        padding: 5px;
        color: $white-2;
        text-transform: uppercase;
        background-color: $black-2;
      }
    }

    .base-yellow-btn {
      width: 100px;
    }
  }
  .my-template-main-tabs {
    .base-tabs {
      background-color: $yellow;
      padding-bottom: 0;
      .ant-tabs-nav {
        width: 70%;
        margin: 0 auto;
        margin-top: 40px;
      }
      .ant-tabs-content-holder {
        background-color: $white-2;
        padding-top: 40px;
        padding-bottom: 100px;
      }
    }
  }
}
