.template {
  .image-container {
    position: relative;
    overflow-y: hidden;
    overflow-x: hidden;
    cursor: pointer;
    .navigation {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;

      &:hover,
      &:focus {
        ~ .item__overlay {
          transform: translate3d(0, 0, 0);
        }
      }
    }
    .image {
      width: 100%;
      height: 250px;
      object-fit: cover;
    }

    .item__overlay {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      position: absolute;
      width: 100%;
      text-align: center;
      top: 0;
      transition: transform 300ms;
      background-color: $yellow;
      transform: translate3d(0, 100%, 0);
    }
  }

  .title {
    margin-top: 10px;
    font-size: 16px;
    margin-bottom: 5px;
    word-wrap: break-word;
  }

  .description {
    font-size: 12px;
  }
}
