.preview-text {
  font-size: 14px;
  line-height: 28px;
  font-weight: 700;
  margin-bottom: 12px;
}

.preview-component {
  box-shadow: 0 0 10px $grey-4;
  user-select: none;

  &-content {
    .breadcrumb-container {
      height: unset;
      padding: 4px 0 3px;
      .breadcrumb {
        font-size: 3px;
        line-height: 3px;
        .breadcrumb-item {
          font-size: 3px;
        }
        .ant-breadcrumb-separator {
          font-size: 3px;
          margin: 0 2px;
        }
      }
    }
  }

  &-header {
    height: 24px;
    background: $white-2;
    padding: 0;
    &-content {
      height: 100%;
      display: flex;
      align-items: center;
    }
    .header-image {
      width: 32px;
    }
    .header-title {
      text-transform: uppercase;
      font-size: 8px;
      margin-left: 15px;
    }
    .header-icon {
      height: 100%;
      margin-left: auto;
      .icon {
        width: 6px;
        g {
          fill: $black-2;
        }
      }
    }
  }
  &-arrow {
    padding: 2px 7px;
    &_icon {
      display: inline-block;
      border: solid $black;
      border-width: 0 2px 2px 0;
      padding: 2px;
      transform: rotate(225deg);
    }
  }
  &-footer {
    background-color: $white;
    padding: 0;
    &-content {
      background-color: $white;
      padding: 0 25px;
    }
    &-item {
      h3 {
        font-size: 4px;
        margin-bottom: 0px;
      }
      p {
        font-size: 3px;
        margin-bottom: 0px;
      }
    }
    &-tail {
      background-color: $white-2;
      padding: 2px 0;
      span {
        font-size: 4px;
      }
    }
  }
}

.preview-component-modal {
  .ant-modal-content {
    .ant-modal-header {
      .ant-modal-title {
        font-size: 20px;
        font-weight: bold;
      }
    }
    .ant-modal-body {
      padding: 0;
      .preview-modal {
        &-content {
          .breadcrumb-container {
            height: 38px;
          }
        }
      
        &-header {
          background: $white-2;
          padding: 0;
          &-content {
            height: 100%;
            display: flex;
            align-items: center;
          }
          .header-image {
            width: 100px;
          }
          .header-title {
            text-transform: uppercase;
            font-size: 24px;
            margin-left: 45px;
          }
          .header-icon {
            height: 100%;
            margin-left: auto;
            .icon {
              g {
                fill: $black-2;
              }
            }
          }
        }
        &-arrow {
          padding: 2px 7px;
          &_icon {
            display: inline-block;
            border: solid $black;
            border-width: 0 4px 4px 0;
            padding: 5px;
            transform: rotate(225deg);
          }
        }
        &-footer {
          background-color: $white;
          padding: 0;
          &-content {
            background-color: $white;
            padding: 20px;
          }
          &-item {
            h3 {
              font-size: 11px;
              margin-bottom: 0px;
            }
            p {
              font-size: 11px;
              margin-bottom: 0px;
            }
          }
          &-tail {
            background-color: $white-2;
            padding: 10px 0;
            span {
              font-size: 11px;
            }
          }
        }
      }
    }
  }
  .close-button {
    text-align: right;
    padding: 0 24px 24px 0;
  }
}