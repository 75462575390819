.web2Publish-main-container {
  background-color: $white-2;
  &_banner {
    background-color: $yellow;
  }
  .web2Publish-main-content {
    min-height: 116px;
    &--title {
      line-height: 28px;
      text-transform: uppercase;
      padding-top: 3px;
      margin-bottom: 20px;
    }
    &--text {
      font-size: 13px;
      line-height: 20px;
      max-width: 750px;
      margin-bottom: 0;
    }
  }

  .web2Publish-main-tabs {
    .base-tabs {
      background-color: $yellow;
      padding-bottom: 0;
      .ant-tabs-nav {
        width: 70%;
        margin: 0 auto;
        margin-top: 16px;
      }
      .ant-tabs-content-holder {
        background-color: $white-2;
        padding-top: 40px;
        padding-bottom: 100px;
      }
    }
  }
}
