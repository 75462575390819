.micro-edit-start-page {
  background-color: $white-2;
  .edit-start-page {
    &_banner {
      background-color: $white;
      padding-top: 34px;
    }
    &_title {
      color: $black;
      font-size: 26px;
      line-height: 36px;
    }
    &_select {
      min-width: 234px;
      .ant-select-selector {
        background-color: $yellow !important;
        border: none;
        height: 44px;
        padding-left: 12px;
      }
      .ant-select-selection-placeholder,
      .ant-select-selection-item {
        color: $black;
        font-size: 13px;
        font-weight: bold;
        line-height: 22px;
        align-self: center;
        padding-top: 2px;
      }
      .ant-select-arrow {
        color: $black;
      }
    }
    &_text {
      color: $black;
      font-size: 13px;
      line-height: 22px;
      font-weight: 700;
    }
    &_tab {
      display: flex;
      align-items: center;
      justify-content: center;

      color: $yellow;
      background-color: $black;
      height: 55px;
      width: 128px;
      user-select: none;
    }
    &_form {
      .headline {
        &-title {
          font-weight: 700;
        }
        &-text {
          min-height: 132px;
        }
      }
      .subpage-status {
        text-transform: capitalize;
      }
      .ant-form-item-label label {
        color: $black;
        font-size: 11px;
        line-height: 28px;
        font-weight: 700;
      }
      .ant-input {
        color: $black;
        font-size: 13px;
        line-height: 22px;
        border: 2px solid $grey-6;
        &:hover, &:focus {
          border-right-width: 2px !important;
        }
        &:focus {
          box-shadow: none;
        }
      }
      .delete-sub-page-button {
        height: unset;
        padding: 4px 10px;
        margin-bottom: 24px;
        span {
          font-size: 14px;
        }
      }
      .form-buttons {
        padding-top: 24px;
        border-top: 1px solid $grey-8;
      }
    }
    &_preview {
      .preview-component {
        cursor: pointer;
      }
      .preview-start-page {
        &_banner {
          min-height: 38px;
          background-color: $yellow;
        }
        &-image {
          padding-bottom: 25px;
          img {
            width: 100%;
            height: 160px;
            object-fit: cover;
          }
        }
        &-headline {
          font-size: 10px;
          line-height: 12px;
          padding-bottom: 5px;
          margin-bottom: 0px;
        }
        &-text-headline {
          font-size: 3px;
          line-height: 6px;
          padding-bottom: 13px;
          margin-bottom: 0;
          max-width: 210px;
        }
        &-sub-headline {
          .ant-col {
            &:nth-child(1) {
              padding-right: 4px;
            }
            &:nth-child(2) {
              padding-left: 4px;
            }
          }
          .preview-sub-headline-title {
            font-size: 8px;
            line-height: 12px;
          }
          .preview-sub-headline-description {
            font-size: 3px;
            line-height: 6px;
          }
          .preview-sub-headline-image img {
            width: 100%;
            height: 62px;
            object-fit: cover;
          }
          .preview-sub-headline-divider {
            margin: 10px 0;
            hr {
              margin: 0;
              border-top: 1px solid $grey;
              border-bottom: 0;
            }
          }
          &:last-child {
            .sub-headline-divider {
              margin-bottom: 20px !important;
            }
          }
        }
        .base-yellow-btn {
          cursor: default;
          font-size: 5px;
          line-height: 5px;
          height: unset;
          padding: 5px 8px;
          span {
            font-size: 5px;
          }
          &:hover,
          &:focus,
          &:active {
            background-color: $yellow;
            color: $black;
            box-shadow: none;
          }
        }
      }
    }
  }
  .form-title {
    color: $black;
    font-size: 14px;
    line-height: 28px;
    font-weight: 700;
    margin-bottom: 18px;
  }
  .sub-headline {
    padding: 24px 0;
    border-top: 1px solid $grey-8;
  }
  .site-informaton-item {
    color: $black;
    font-size: 13px;
    line-height: 22px;
    padding-bottom: 25px;
    p {
      margin-bottom: 0;
    }
    &-image {
      width: 133px;
    }
    &-text-headline {
      white-space: pre-wrap;
    }
    &-status {
      text-transform: capitalize;
    }
  }
  .sub-page-items {
    padding-top: 24px;
    border-top: 2px solid $grey-8;
  }
  .edit-button {
    margin-top: 50px;
  }
}
.preview-modal-start-page {
  .preview-start-page {
    &_banner {
      background-color: $yellow;
      .preview-start-page-text-headline {
        margin-bottom: 0;
        padding-bottom: 20px;
      }
    }
    &-image {
      margin-bottom: 40px;
      img {
        width: 100%;
        height: 500px;
        object-fit: cover;
      }
    }
    &-sub-headline {
      .ant-col {
        &:nth-child(1) {
          padding-right: 12px;
        }
        &:nth-child(2) {
          padding-left: 12px;
        }
      }
      .preview-sub-headline-title {
        font-weight: bold;
      }
      .preview-sub-headline-description {
        margin-bottom: 20px;
      }
      .preview-sub-headline-image img {
        width: 100%;
        height: 200px;
        object-fit: cover;
      }
      .preview-sub-headline-divider {
        margin: 25px 0;
        hr {
          margin: 0;
          border-top: 1px solid $grey;
          border-bottom: 0;
        }
      }
    }
  }
}