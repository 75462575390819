@import './UserManagementList/index';
@font-face {
  font-family: 'ClanWebPro-News';
  src: url('../../../../../assets/fonts/web/ClanWebPro-News.woff') format('woff');
}
.user-management-content {
  padding-top: 34px;
  padding-bottom: 22px;
  h1 {
    font-family: 'ClanWebPro-News', sans-serif;
    font-size: 26px;
    text-transform: uppercase;
    font-weight: 700;
    line-height: 36px;
    margin-bottom: 0;
  }

  &_banner {
    background-color: $white;
  }
}

.user-management-tabs {
  .base-tabs {
    background-color: $white;
  }
  .ant-tabs-nav {
    font-family: 'ClanWebPro-News', sans-serif;
    width: 70%;
    margin: 0 auto;
  }
  .ant-tabs-content-holder {
    background-color: $white-2;
    padding-top: 32px;

    .ant-tabs-content {
      font-family: 'ClanWebPro-News', sans-serif;
    }
  }


}
