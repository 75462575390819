@font-face {
  font-family: 'ClanWebPro-News';
  src: url('../../assets/fonts/web/ClanWebPro-News.woff') format('woff');
}

.footer {
  background-color: $white !important;
  padding: 0;
  .content_footer {
    background-color: $grey;
    &-item {
      h3 {
        color: $black;
        font-size: 12px;
        margin-bottom: 10px;
      }
      p, a {
        font-family: 'ClanWebPro-News', sans-serif;
        color: $black;
        font-size: 12px;
        line-height: 20px;
        margin-bottom: 0;
      }
      .email-icon {
        margin-left: 5px;
      }
    }

    .social-media-icon {
      margin-left: -8px;
    }

    &-tail {
      background-color: $white-2;
      padding: 20px 0;
      span {
        font-size: 11px;
        color: $black;
      }
    }
  }

  .background-grey {
    h3, p, span, a {
      color: $white-2;
    }
    &:last-child {
      border-top: 1px solid $grey-3;
    }
  }
}
