// common
$yellow: #ffed00;

$black: #2b2b2b;
$black-2: #000000;

$white: #f8f8f8;
$white-2: #ffffff;

$grey: #ededed;
$grey-2: #6e6e6e;
$grey-3: #dcdcdc;
$grey-4: #b7b7b7;
$grey-5: #aaaaaa;
$grey-6: #757575;
$grey-7: #f5f5f5;
$grey-8: #979797;

$blue: #3368ff;
$blue-1: #007bff;
$blue-2: #0091FF;

$red: #ff4633;

$green: #4fae47;
