.disclaimer-container {
  .disclaimer-banner {
    background-color: $yellow;
    margin-bottom: 50px;
    padding-bottom: 20px;
  }
  .disclaimer-content {
    color: $black;
    p {
      margin-bottom: 15px;
    }
  }
}