.forbidden-content {
  width: 492px;
  margin: 0 auto;

  &-code {
      font-size: 100px;
  }

  &-text {
      font-size: 19px;
  }
}
