.my-profile {
  &_banner {
    background-color: $yellow;
    min-height: 120px;
  }
  &-container {
    background-color: $white;

    .user-information {
      margin-top: 50px;
      &--title {
        color: $black;
        font-size: 14px;
        margin-bottom: 18px;
      }
      &_item {
        color: $black;
        font-size: 13px;
        line-height: 22px;
        p {
          margin-bottom: 0;
        }
        .base-select {
          width: 100%;
        }
      }
      &_row:nth-child(2) {
        margin-top: 16px;
        margin-bottom: 50px;
      }
    }

    .my-profile {
      &_item {
        &--title {
          color: $grey-6;
          font-size: 11px;
          font-family: 'ClanWebPro-Bold', sans-serif;
          
        }
        &--text {
          font-weight: 700;
          line-height: 22px;
        }
      }
    }
  }

  &--title {
    padding-top: 3px;
    margin-bottom: 14px;
  }
}

.user-language-select {
  .ant-select-item-option-selected {
    background-color: $grey;
  }
}
