@font-face {
  font-family: 'ClanWebPro-News';
  src: url('../.././../assets/fonts/web/ClanScWebPro-News.woff') format('woff');
}

.base-tabs {
  background-color: $white;
  .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab {
    color: $black;
    font-size: 13px;
    font-weight: bold;
    text-align: center;

    min-width: 128px;
    min-height: 55px;
    border: none;
    border-radius: 0;
    background-color: $grey;

    .ant-tabs-tab-btn {
  font-family: 'ClanWebPro-News', sans-serif;

      width: 100%;
    }

    &:hover {
      color: $yellow;
      background-color: $black;
    }
  }
  .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab-active,
  .ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab-active {
    background-color: $black;
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    
    color: $yellow;
  }
}
